export default function memberFormStore(state, newState) {
  const {
    nume,
    prenume,
    cnp,
    judNastere,
    locNastere,
    dataBotez,
    dataMembru,
    domiciliu,
    tel,
    captchaToken,
    semnatura,
    consimtamant,
  } = newState;
  return {
    ...state,
    ...{
      nume,
      prenume,
      cnp,
      judNastere,
      locNastere,
      dataBotez,
      dataMembru,
      domiciliu,
      tel,
      captchaToken,
      semnatura,
      consimtamant,
    },
  };
}
