import React from "react";

export default function Member({ memberDetails, id, onViewMember }) {
  const { nume, prenume, dataCompletare } = memberDetails;

  return (
    <tr className="even:bg-slate-100 odd:bg-slate-200">
      <td className="text-xs font-medium px-6 py-4 whitespace-nowrap tracking-wider">
        {id}
      </td>
      <td className="text-xs font-medium px-6 py-4 whitespace-nowrap tracking-wider">
        {nume}
      </td>
      <td className="text-xs font-medium px-6 py-4 whitespace-nowrap tracking-wider">
        {prenume}
      </td>
      <td className="text-xs font-medium px-6 py-4 whitespace-nowrap tracking-wider">
        {new Date(dataCompletare).toLocaleString()}
      </td>
      <td className="text-xs font-medium px-6 py-4 whitespace-nowrap tracking-wider">
        <button
          onClick={() => onViewMember(memberDetails)}
          className="mt-2 bg-blue-500 text-white py-2 px-4 rounded"
        >
          Vizualizare
        </button>
      </td>
    </tr>
  );
}
