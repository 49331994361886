import { z } from "zod";
import { regions } from "../constants";

const invalidRegion = () => ({ message: "Judet invalid" });

/**
 * Validate member
 * @param {Object} memberData
 * @return {{prenume?: ZodString["_output"], nume?: ZodString["_output"]}}
 */
export default function memberValidator(memberData) {
  const member = z.object({
    nume: z.string().min(2).max(20),
    prenume: z.string().min(2).max(30),
    cnp: z
      .string()
      .length(13)
      .regex(
        new RegExp(
          /^[1-8][0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])\d{6}$/,
        ),
        "Valoare invalida",
      ),
    judNastere: z.enum(regions, {
      errorMap: invalidRegion,
    }),
    locNastere: z.string().min(2).max(20),
    dataBotez: z.coerce.date(),
    dataMembru: z.coerce.date(),
    // domiciliu,
    tel: z
      .string()
      .regex(new RegExp(/^(\+\d{1,4}\s?)?(\d{10,15})$/), "Valoare invalida"),
    semnatura: z
      .string()
      .min(5000, "Semnatura lipseste sau este prea scurta")
      .max(15000, "Semnatura prea lunga"),
    consimtamant: z.literal(true, {
      errorMap: () => ({ message: "Este obligatoriu" }),
    }),
  });
  console.log(memberData);
  return member.safeParse(memberData);
}
