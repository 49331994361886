import React from "react";

const ErrorAlert = ({ message, errors }) => {
  return (
    <div
      className="bg-red-100 border border-red-400 text-red-700 mb-4 px-4 py-3 rounded relative"
      role="alert"
    >
      <p className="font-bold mb-4">{message}</p>
      <ul>
        {errors.map((errorMessage, i) => (
          <li className="mb-2" key={i}>
            {errorMessage}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ErrorAlert;
