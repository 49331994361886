const labelMap = {
  nume: "Nume",
  prenume: "Prenume",
  cnp: "CNP",
  judNastere: "Judet nastere",
  locNastere: "Localitate nastere",
  dataBotez: "Data botezului nou testamental",
  dataMembru:
    "Data dobandirii calitalii de membru al Bisericii Penticostale Maranata Baia Mare",
  domiciliu: "Domiciliul actual",
  tel: "Telefon",
  semnatura: "Semnatura olografa",
  captcha: "Cod de securitate",
  consimtamant: "Consimtamant",
};

export default new Map(Object.entries(labelMap));
