import React from "react";
export default function PaginationButton({
  onClickButton,
  children,
  className,
  disabled,
}) {
  const btnClickHandler = () => {
    onClickButton();
  };

  return (
    <button
      className={`${className} border rounded-md p-2 bg-blue-500 disabled:bg-blue-300 text-white text-xs font-medium whitespace-nowrap tracking-wider`}
      onClick={btnClickHandler}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
