import React from "react";
export default function FilterMembers({ onFilter, disabled }) {
  const handleFilterChange = (e) => {
    onFilter(e.target.value);
  };

  return (
    <>
      <input
        type="text"
        disabled={disabled}
        placeholder="Filtrare dupa nume"
        className="border rounded-md p-2"
        onChange={handleFilterChange}
      />
    </>
  );
}
