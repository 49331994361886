import React, { useReducer } from "react";
import MemberList from "../components/MemberList";
import appStore from "../store/appStore";
import { PrivateKeyContext } from "../contexts/PrivateKeyContext";

export default function MemberAdminPage() {
  const [state, dispatch] = useReducer(appStore, {
    privateKey: "",
    passphrase: "",
  });

  const onInputKey = ({ target }) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const [, res] = e.target.result.split(",");
      dispatch({ privateKey: atob(res) });
    };
    reader.readAsDataURL(target.files[0]);
  };

  return (
    <>
      <header className="bg-blue-500 p-4 text-white">
        <h1 className="text-2xl font-semibold">Lista membri</h1>
      </header>
      <main className="container mx-auto p-4">
        {!state.privateKey && (
          <div className="mb-4">
            <label htmlFor="privateKey">Incarca cheia:</label>
            <input type="file" name="privateKey" onInput={onInputKey} />
          </div>
        )}

        {/*<textarea*/}
        {/*  onInput={onInputKey}*/}
        {/*  placeholder="Cheia privata"*/}
        {/*  name="privateKey"*/}
        {/*/>*/}
        {/*<input onInput={onInputKey} placeholder="parola" name="passphrase" />*/}
        <PrivateKeyContext.Provider value={state}>
          {state.privateKey && <MemberList />}
        </PrivateKeyContext.Provider>
      </main>
    </>
  );
}
