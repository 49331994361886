import React from "react";

export default function SuccessAlert({ message }) {
  return (
    <div
      className="bg-green-100 border border-green-400 text-green-700 mb-4 px-4 py-3 rounded relative"
      role="alert"
    >
      <p className="font-bold">{message}</p>
    </div>
  );
}
