import labelMap from "./labelMap";
import regions from "./regions";

const agreement = `îmi dau consimțământul pentru prelucrarea datelor cu caracter
personal în scopul desfășurării activităților specifice Bisericii
Penticostale Maranata. (Spre exemplificare, dar lista poate fi
completată: transmisiuni slujbe online pe site-ul bisericii
”www.maranatabm.ro”, canalul de YouTube, pagina și grupul de pe
Facebook / Instagram, efectuarea de poze sau instantanee în scopul
promovării activităților specifice bisericii, registrul de membri,
grupul de membri de pe rețeaua WhatsApp, precum și alte acțiuni
care se desfășoară sub egida Bisericii Penticostale Maranata din
Baia Mare.)`;

export { labelMap, regions, agreement };
