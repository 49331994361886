import Regions from "./Regions";
import React, { useRef, useState } from "react";
import Turnstile from "react-turnstile";
import SignatureCanvas from "react-signature-canvas";
import { agreement, labelMap } from "../constants";

export default function MemberForm({ loading, onSubmitForm }) {
  const [captchaToken, setCaptchaToken] = useState();
  const [consimtamant, setConsimtamant] = useState(false);
  const [signRef, dateMemberRef] = [useRef(), useRef()];
  const submitFormHandler = (e) => {
    e.preventDefault();
    const formData = Object.fromEntries(
      Array.from(e.target).map(({ name, value }) => [name, value]),
    );
    const semnatura = signRef.current.toDataURL("image/png");
    onSubmitForm({ ...formData, captchaToken, semnatura, consimtamant });
  };
  const onVerifyCaptcha = (token) => {
    setCaptchaToken(token);
  };

  const onErrorCaptcha = (error, t) => {
    setTimeout(() => t?.reset(), 8000);
  };
  const onResetCaptcha = () => {
    setCaptchaToken(undefined);
  };
  const resetSignature = () => {
    signRef?.current.clear();
  };

  const onDBChange = (e) => {
    if (!dateMemberRef.current.value) {
      dateMemberRef.current.value = e.target.value;
    }
  };

  return (
    <form onSubmit={submitFormHandler} className="w-full md:w-1/2 mx-auto">
      <div className="relative z-0 w-full mb-6 group">
        <label className="form-label">{labelMap.get("nume")}</label>
        <input id="nume" name="nume" type="text" className="form-input" />
      </div>

      <div className="mb-8">
        <label className="form-label">{labelMap.get("prenume")}</label>
        <input name="prenume" type="text" className="form-input" />
      </div>

      <div className="mb-8">
        <label className="form-label">{labelMap.get("cnp")}</label>
        <input name="cnp" type="number" className="form-input" />
      </div>

      <div className="mb-8">
        <label className="form-label">{labelMap.get("judNastere")}</label>
        <Regions name="judNastere" className="form-input" />
      </div>

      <div className="mb-8">
        <label className="form-label">{labelMap.get("locNastere")}</label>
        <input name="locNastere" type="text" className="form-input" />
      </div>

      <div className="mb-8">
        <label className="form-label">{labelMap.get("dataBotez")}</label>
        <input
          onChange={onDBChange}
          name="dataBotez"
          type="date"
          className="form-input"
        />
      </div>

      <div className="mb-8">
        <label className="form-label">{labelMap.get("dataMembru")}</label>
        <input
          ref={dateMemberRef}
          name="dataMembru"
          type="date"
          className="form-input"
        />
      </div>

      <div className="mb-8">
        <label className="form-label">{labelMap.get("domiciliu")}</label>
        <input name="domiciliu" type="text" className="form-input" />
      </div>

      <div className="mb-8">
        <label className="form-label">{labelMap.get("tel")}</label>
        <input name="tel" type="tel" className="form-input" />
      </div>

      <div className="mb-8">
        <label className="form-label">{labelMap.get("semnatura")}</label>
        <SignatureCanvas
          ref={signRef}
          canvasProps={{
            width: 300,
            height: 200,
            className: "box-border border border-gray-600 mb-4",
          }}
        />
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white text-sm font-semibold py-2 px-4 rounded-lg"
          onClick={resetSignature}
          type="button"
        >
          Reseteaza semnatura
        </button>
      </div>

      <div className="mb-8">
        <Turnstile
          sitekey="0x4AAAAAAALh2mnKNJbC75Kb"
          onVerify={onVerifyCaptcha}
          onError={onErrorCaptcha}
          onTimeout={onResetCaptcha}
        />
      </div>

      <div className="mb-8">
        <label htmlFor="consimtamant" className="form-label text-xs">
          <input
            onChange={() => setConsimtamant(!consimtamant)}
            defaultChecked={false}
            id="consimtamant"
            type="checkbox"
            className="mr-2"
          />
          <span>{agreement}</span>
        </label>
      </div>

      <div className="mb-8">
        {!loading && (
          <button
            disabled={!captchaToken}
            type="submit"
            className="w-full bg-blue-500 disabled:bg-blue-300 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg"
          >
            Trimite
          </button>
        )}
        {loading && (
          <span className="font-semibold py-2 px-4">Se trimit datele...</span>
        )}
      </div>
    </form>
  );
}
