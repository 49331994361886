import React from "react";
import { regions } from "../constants";
export default function Regions(props) {
  return (
    <select {...props}>
      {regions.map((region, index) => (
        <option key={index}>{region}</option>
      ))}
    </select>
  );
}
