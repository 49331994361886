import React, { useEffect, useReducer, useRef, useState } from "react";
import memberFormStore from "../store/memberFormStore";
import ErrorAlert from "../components/ErrorAlert";
import MemberForm from "../components/MemberForm";
import memberValidator from "../validators/memberValidator";
import { labelMap } from "../constants";
import SuccessAlert from "../components/SuccessAlert";

export default function MemberFormPage() {
  const [state, dispatch] = useReducer(memberFormStore);
  const [errors, setErrors] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const alertRef = useRef();
  const isValidResponse = ({ success, error }) => {
    if (success) {
      return true;
    }
    const errors = error.issues.map(
      ({ message, path: [field] }) => `${labelMap.get(field)} - ${message}`,
    );
    setErrorMessage("Au aparut urmatoarele erori:");
    setErrors(errors);
    return false;
  };

  const saveFormData = async () => {
    // eslint-disable-next-line no-undef
    const { REACT_APP_API_PREFIX } = process.env;
    const response = await fetch(`${REACT_APP_API_PREFIX}/member`, {
      body: JSON.stringify(state),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    });

    if (response.status === 400) {
      const jsonResponse = await response.json();
      return isValidResponse(jsonResponse);
    }

    if (response.status > 400) {
      setErrorMessage("A intervenit o problema.");
      setErrors(["Va rugam revenit mai tarziu."]);
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (errors.length > 0 || successMessage) {
      alertRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [errors, successMessage]);

  useEffect(() => {
    if (!state) {
      return;
    }

    setErrors([]);
    const response = memberValidator(state);

    if (isValidResponse(response)) {
      setLoading(true);
      saveFormData()
        .then((res) => {
          if (res) {
            setSuccessMessage("Datele au fost trimise cu succes");
          }
        })
        .catch((e) => console.error(e))
        .finally(() => setLoading(false));
    }
  }, [state]);

  const submitFormHandler = (formData) => {
    dispatch(formData);
  };

  return (
    <>
      <main className="container mx-auto p-4 font-medium tracking-wider">
        <div className="bg-white border-solid border-1 drop-shadow p-4 sm:w-2/3 md:w-3/4 lg:w-1/2 w-full mx-auto">
          <h1 className="mb-8 text-center text-xl">
            FORMULAR ACTUALIZARE DATE ÎN REGISTRUL DE MEMBRI AL BISERICII
            PENTICOSTALE MARANATA BAIA MARE
          </h1>
          <div ref={alertRef} className="mb-8">
            {errors.length > 0 && (
              <ErrorAlert message={errorMessage} errors={errors} />
            )}
            {successMessage && (
              <>
                <SuccessAlert message={successMessage} />
                <a href="">&gt;&gt; Completeaza un alt formular</a>
              </>
            )}
          </div>
          {!successMessage && (
            <MemberForm loading={loading} onSubmitForm={submitFormHandler} />
          )}
        </div>
      </main>
    </>
  );
}
