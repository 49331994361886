import React, { useEffect, useRef } from "react";
import { agreement, labelMap } from "../constants";

export default function PrintMember({ currentMember }) {
  const printRef = useRef();

  useEffect(() => {
    if (!currentMember) {
      return;
    }
    const printHtml = printRef.current.outerHTML;
    const WinPrint = window.open(
      "",
      "",
      "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0",
    );
    WinPrint.document.write(printHtml);
    // WinPrint.document.close();
    // WinPrint.focus();
    // WinPrint.print();
    // WinPrint.close();
  }, [currentMember]);

  return (
    <div style={{ display: "none" }}>
      <div
        ref={printRef}
        style={{ fontSize: "12pt", padding: "10pt", background: "#FFFFFF" }}
      >
        <p style={{ fontSize: "10pt" }}>
          CULTUL CREȘTIN PENTICOSTAL DIN ROMÂNIA
          <br />
          COMUNITATEA MARAMUREȘULUI ȘI SĂTMARULUI
          <br />
          BISERICA PENTICOSTALĂ &ldquo;MARANATA&rdquo; BAIA MARE
          <br />
          <u>www.maranatabm.ro</u>
        </p>
        <p
          style={{ textAlign: "center", fontWeight: "bold", margin: "25pt 0" }}
        >
          FORMULAR ACTUALIZARE DATE ÎN REGISTRUL DE MEMBRI
          <br />
          AL BISERICII PENTICOSTALE MARANATA BAIA MARE
        </p>
        <div style={{ textAlign: "justify" }}>
          <p>
            <strong>{labelMap.get("nume")}:</strong> {currentMember.nume};{" "}
            <strong>{labelMap.get("prenume")}:</strong> {currentMember.prenume};
          </p>
          <p>
            <strong>{labelMap.get("cnp")}:</strong> {currentMember.cnp};
          </p>
          <p>
            <strong>Locul nașterii: localitate:</strong>{" "}
            {currentMember.locNastere}; <strong>județ:</strong>{" "}
            {currentMember.judNastere};
          </p>
          <p>
            <strong>{labelMap.get("dataBotez")}</strong>:{" "}
            {new Date(currentMember.dataBotez).toLocaleDateString()};
          </p>
          <p>
            <strong>{labelMap.get("dataMembru")}</strong>:{" "}
            {new Date(currentMember.dataMembru).toLocaleDateString()};
          </p>
          <p>
            <strong>{labelMap.get("domiciliu")}</strong>:{" "}
            {currentMember.domiciliu};
          </p>
          <p>
            <strong>{labelMap.get("tel")}</strong>: {currentMember.tel}
          </p>
          <p
            style={{ margin: "35pt 0", fontSize: "10pt", fontStyle: "italic" }}
          >
            <small>
              <u>Notă</u>
            </small>
            <br />
            <strong>
              Subsemnatul: {currentMember.nume} {currentMember.prenume}
            </strong>
            , {agreement}
          </p>
          <p style={{ float: "left" }}>
            <strong>Data completării:</strong>{" "}
            {new Date(currentMember.dataCompletare).toLocaleDateString()}
          </p>
          <p style={{ float: "right" }}>
            <strong>Semnătura:</strong>
            <br />
            <img src={currentMember.semnatura} />
          </p>
        </div>
      </div>
    </div>
  );
}
