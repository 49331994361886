import React, { useContext, useEffect, useState } from "react";
import Member from "./Member";
import Spinner from "./Spinner";
import FilterMembers from "./FilterMembers";
import { PrivateKeyContext } from "../contexts/PrivateKeyContext";
import { crypter } from "../services/crypter";
import PaginationButton from "./PaginationButton";
import PrintMember from "./PrintMember";

export default function MemberList() {
  const { privateKey, passphrase } = useContext(PrivateKeyContext);
  const [loading, setLoading] = useState(false);
  const [memberList, setMemberList] = useState([]);
  const [currentMember, setCurrentMember] = useState(null);
  const [filteredMemberList, setFilteredMemberList] = useState([]);
  const [nextUrl, setNextUrl] = useState();
  const [prevUrl, setPrevUrl] = useState();

  const loadData = (url) => {
    setLoading(true);
    setMemberList([]);
    setFilteredMemberList([]);
    fetch(url)
      .then((r) => r.json())
      .then(({ nextUrl, prevUrl, data }) => {
        setNextUrl(nextUrl);
        setPrevUrl(prevUrl);
        const decryptedData = data.map((m) => {
          m._datePersonale =
            JSON.parse(
              crypter.decrypt(m.datePersonale, privateKey, passphrase),
            ) || {};
          console.log(m);
          return m;
        });
        setMemberList(decryptedData);
        setFilteredMemberList(decryptedData);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const { REACT_APP_API_PREFIX } = process.env;
    loadData(`${REACT_APP_API_PREFIX}/members`);
  }, []);
  const onFilterHandler = (filterText) => {
    const filterTextLowercase = filterText?.toLowerCase();
    if (!filterText) {
      setFilteredMemberList(memberList);
    } else {
      const fml = memberList.filter(
        ({ _datePersonale: { nume, prenume } }) =>
          nume.toLowerCase().includes(filterTextLowercase) ||
          prenume.toLowerCase().includes(filterTextLowercase),
      );
      setFilteredMemberList(fml);
    }
  };

  const onViewMember = (memberDetails) => {
    setCurrentMember(memberDetails);
  };

  return (
    <>
      <div className="mb-4">
        <FilterMembers onFilter={onFilterHandler} disabled={loading} />
      </div>
      <table className="mb-4 min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              ID
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Nume
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Prenume
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Data inregistrarii
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Vizualizare membru
            </th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan="5" className="text-center px-6 py-4">
                <Spinner />
              </td>
            </tr>
          )}
          {!loading && filteredMemberList.length === 0 && (
            <tr className="bg-slate-200">
              <td
                colSpan="5"
                className="text-xs font-medium tracking-wider px-6 py-4 whitespace-nowrap text-left"
              >
                Nu s-au gasite inregistrari
              </td>
            </tr>
          )}
          {filteredMemberList.map(
            ({ id, dataCompletare, _datePersonale, semnatura }) => (
              <Member
                key={id}
                id={id}
                memberDetails={{ dataCompletare, ..._datePersonale, semnatura }}
                onViewMember={onViewMember}
              />
            ),
          )}
        </tbody>
      </table>
      <div className="flex items-center">
        {prevUrl && (
          <PaginationButton
            onClickButton={() => loadData(prevUrl)}
            className="group flex items-center"
            disabled={loading}
          >
            Pagina anterioara
          </PaginationButton>
        )}
        {nextUrl && (
          <PaginationButton
            onClickButton={() => loadData(nextUrl)}
            className="group ml-auto flex items-center"
            disabled={loading}
          >
            Pagina urmatoare
          </PaginationButton>
        )}
      </div>
      {currentMember && <PrintMember currentMember={currentMember} />}
    </>
  );
}
