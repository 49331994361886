import JSEncrypt from "jsencrypt";

export const crypter = (() => {
  const jsEncript = new JSEncrypt();
  return {
    // eslint-disable-next-line no-unused-vars
    decrypt: (msg, privateKey, passphrase) => {
      jsEncript.setPrivateKey(privateKey);
      const decryptedMsg = jsEncript.decrypt(msg);

      if (decryptedMsg === false) {
        throw new Error("Cannot decrypt message");
      }

      return decryptedMsg;
    },
    encrypt: (msg, publicKey) => {
      jsEncript.setPublicKey(publicKey);
      return jsEncript.encrypt(msg);
    },
  };
})();

export async function sha1(str) {
  const enc = new TextEncoder();
  const hash = await crypto.subtle.digest("SHA-1", enc.encode(str));
  return Array.from(new Uint8Array(hash))
    .map((v) => v.toString(16).padStart(2, "0"))
    .join("");
}
